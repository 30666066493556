<template>
  <div class="customer-information">
    <form class="form-contract" method="post">
      <input type="hidden" name="authenticity_token" :value="authenToken" autocomplete="off">
      <div class="subscriber-section r:max-w-[672px] mx-auto mb-16">
        <h2 class="r:text-[24px] leading-[1.53] r:tracking-[0.25px] px-6 mb-4">{{ viewsJa.contracts.blocks.customer_information.groups.subscriber_information.group_title }}</h2>
        <!-- applicator info -->
        <div class="px-4 py-6 bg-white border-b applicator-info xs:px-6 xs:py-8 border-neutral-gray-100">
          <h3 class="r:text-[18px] text-primary-600 leading-[1.53] r:tracking-[0.25px] mb-6 xs:mb-8">{{ viewsJa.contracts.blocks.customer_information.groups.subscriber_information.user_section }}</h3>
          <!-- full name -->
          <div class="mb-4 form-group">
            <label class="flex items-center mb-2">
              <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.full_name }}</span>
              <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
            </label>
            <div class="flex -mx-0.5">
              <div class="w-1/2 px-0.5">
                <text-input
                  v-model="form.lastName"
                  class="w-full"
                  id="user_last_name"
                  name="user[last_name]"
                  :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.last_name"
                  :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.form.lastName.$error ? 'form-invalid' : ''} ${v$.form.lastName.$dirty ? 'form-dirty' : ''} ${fieldServerErr.last_name ? 'form-invalid-server' : ''}`"
                  @change="handleSetTouch('lastName'); handleCheckServerError('user_last_name', ['last_name']); setTouchParallel('lastNameKana', 'lastName')"
                  @blur="handleCheckLength('user-kana')"
                  @input="setKana($event, 'lastName', 'lastNameKana')"
                />
              </div>
              <div class="w-1/2 px-0.5">
                <text-input
                  v-model="form.firstName"
                  class="w-full"
                  id="user_first_name"
                  name="user[first_name]"
                  :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.first_name"
                  :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.form.firstName.$error ? 'form-invalid': ''} ${v$.form.firstName.$dirty ? 'form-dirty' : ''} ${fieldServerErr.first_name ? 'form-invalid-server' : ''}`"
                  @change="handleSetTouch('firstName'); handleCheckServerError('user_first_name', ['first_name']); setTouchParallel('firstNameKana', 'firstName')"
                  @blur="handleCheckLength('user-kana')"
                  @input="setKana($event, 'firstName', 'firstNameKana');"
                />
              </div>
            </div>
            <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.form.lastName.$error || fieldServerErr.last_name">
              <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.last_name }}</span>
            </label>
            <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="first_name" v-if="v$.form.firstName.$error || fieldServerErr.first_name">
              <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.first_name }}</span>
            </label>
          </div>
          <!-- full name kana -->
          <div class="mb-4 form-group">
            <label class="flex items-center mb-2">
              <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.full_name_kana }}</span>
              <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
            </label>
            <div class="flex -mx-0.5">
              <div class="w-1/2 px-0.5">
                <text-input
                  v-model="form.lastNameKana"
                  class="w-full"
                  id="user_last_name_kana"
                  name="user[last_name_kana]"
                  :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.last_name_kana"
                  data-group-length="full-name-kana"
                  :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.form.lastNameKana.$error || fieldLengthErr.fullNameKana ? 'form-invalid' : ''} ${v$.form.lastNameKana.$dirty ? 'form-dirty' : ''} ${fieldServerErr.last_name_kana ? 'form-invalid-server' : ''}`"
                  @change="handleSetTouch('lastNameKana'); handleCheckServerError('user_last_name_kana', ['last_name_kana', 'fullname_kana'])"
                  @blur="handleCheckLength('user-kana')"
                />
              </div>
              <div class="w-1/2 px-0.5">
                <text-input
                  v-model="form.firstNameKana"
                  class="w-full"
                  id="user_first_name_kana"
                  name="user[first_name_kana]"
                  :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.first_name_kana"
                  data-group-length="full-name-kana"
                  :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.form.firstNameKana.$error || fieldLengthErr.fullNameKana ? 'form-invalid' : ''} ${v$.form.firstNameKana.$dirty ? 'form-dirty' : ''} ${fieldServerErr.first_name_kana ? 'form-invalid-server' : ''}`"
                  @change="handleSetTouch('firstNameKana'); handleCheckServerError('user_first_name_kana', ['first_name_kana', 'fullname_kana'])"
                  @blur="handleCheckLength('user-kana')"
                />
              </div>
            </div>
            <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.form.lastNameKana.$error || v$.form.firstNameKana.$error || fieldLengthErr.fullNameKana || fieldServerErr.last_name_kana || fieldServerErr.first_name_kana">
              <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.full_name_kana }}</span>
            </label>
          </div>
          <!-- date of birth -->
          <div class="mb-4 form-group">
            <label class="flex items-center mb-2">
              <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.date_of_birth }}</span>
              <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
            </label>
            <div class="flex -mx-0.5">
              <dropdown-date
                @handle-update-date="fillDob"
                dropdownType="user"
                :wrapSelectClass="dropdownCls"
                class="flex flex-wrap w-full"
                :maxAge="100"
                :minAge="20"
                :dateInit="form.dateOfBirth"
                :birthYear="userBirthYear"
                :serverError="fieldServerErr.date_of_birth"
                :hasLabelDate="false"
              />
              <text-input
                v-model="v$.form.dateOfBirth.$model"
                class="wrap-hidden"
                type="hidden"
                id="user_date_of_birth"
                name="user[date_of_birth]"
                :customClass="`${v$.form.dateOfBirth.$error ? 'form-invalid' : ''} ${v$.form.dateOfBirth.$dirty ? 'form-dirty' : ''} ${fieldServerErr.date_of_birth ? 'form-invalid-server' : ''}`"
              />
            </div>
            <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="date_of_birth" v-if="v$.form.dateOfBirth.$error || fieldServerErr.date_of_birth">
              <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.date_of_birth }}</span>
            </label>
          </div>
          <!-- gender -->
          <div class="mb-4 form-group">
            <label class="flex items-center mb-2">
              <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.gender }}</span>
              <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
            </label>
            <div class="flex -mx-0.5">
              <div class="px-0.5">
                <radio-input
                  v-model="form.gender"
                  id="user_gender_male"
                  name="user[gender]"
                  :label="viewsJa.contracts.blocks.customer_information.fields.gender.male"
                  :class="`radio-bar-primary mb-2 ${v$.form.gender.$error ? 'form-invalid' : ''} ${v$.form.gender.$dirty ? 'form-dirty' : ''} ${fieldServerErr.gender ? 'form-invalid-server' : ''}`"
                  radioValue="0"
                  radioClass="r:text-[16px] leading-[1.53] r:tracking-[0.25px]"
                  @change="handleSetTouch('gender'); handleCheckServerError('user_gender_male', ['gender'])"
                ></radio-input>
              </div>
              <div class="px-0.5">
                <radio-input
                  v-model="form.gender"
                  id="user_gender_female"
                  name="user[gender]"
                  :label="viewsJa.contracts.blocks.customer_information.fields.gender.female"
                  :class="`radio-bar-primary ${v$.form.gender.$error ? 'form-invalid' : ''} ${v$.form.gender.$dirty ? 'form-dirty' : ''} ${fieldServerErr.gender ? 'form-invalid-server' : ''}`"
                  radioValue="1"
                  radioClass="r:text-[16px] leading-[1.53] r:tracking-[0.25px]"
                  @change="handleSetTouch('gender'); handleCheckServerError('user_gender_male', ['gender'])"
                ></radio-input>
              </div>
            </div>
            <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="date_of_birth" v-if="v$.form.gender.$error || fieldServerErr.gender">
              <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.gender }}</span>
            </label>
          </div>
          <!-- email -->
          <div class="form-group">
            <label class="flex items-center mb-2">
              <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.email }}</span>
              <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
            </label>
            <div class="flex -mx-2">
              <div class="w-full px-2">
                <text-input
                  v-model="form.email"
                  class="w-full"
                  id="user_email"
                  name="user[email]"
                  :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.email"
                  :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.form.email.$error ? 'form-invalid' : ''} ${v$.form.email.$dirty ? 'form-dirty' : ''} ${fieldServerErr.email ? 'form-invalid-server' : ''}`"
                  @change="handleSetTouch('email'); handleCheckServerError('user_email', ['email'])"

                />
              </div>
            </div>
            <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.form.email.$error || fieldServerErr.email">
              <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.email }}</span>
            </label>
          </div>
        </div>
        <!-- applicator address -->
        <div class="applicator-address bg-white r:max-w-[672px] mx-auto px-4 xs:px-6 py-6 xs:py-8 border-b border-neutral-gray-100">
          <h3 class="r:text-[18px] text-primary-600 leading-[1.53] r:tracking-[0.25px] mb-6 xs:mb-8">{{ viewsJa.contracts.blocks.customer_information.groups.subscriber_information.address_section }}</h3>
          <!-- postal code -->
          <div class="mb-4 form-group">
            <label class="flex items-center mb-2">
              <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.postal_code }}</span>
              <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
            </label>
            <div class="flex items-center mb-2 -mx-0.5">
              <places
                class="r:w-[100px] px-0.5"
                :apiKey="apiKey"
                @handle-update-location="fillLocation"
                @handle-postal-code-error="checkPostalCodeError"
                :serverError="formErrors !== undefined && formErrors.postal_code !== undefined ? true : false"
                :postalCodeVal="form.postalCode"
                :isTriggerAddress="form.prefecture && form.municipality && form.town ? false : true"
              />
              <div class="px-0.5">
                <a href="https://www.post.japanpost.jp/zipcode/" class="text-accent-500 r:text-[14px] leading-[1.53] hover:opacity-70 transition r:tracking-[0.25px]" target="_blank" rel="noopener">{{ viewsJa.contracts.blocks.customer_information.fields_note.postal_code_refer }}</a>
              </div>
            </div>
            <span class="block r:text-[14px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-400">{{ viewsJa.contracts.blocks.customer_information.fields_note.postal_code }}</span>
            <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="postal_code" v-if="postalCodeErr || fieldServerErr.postal_code">
              <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.postal_code }}</span>
            </label>
          </div>
          <!-- prefecture & city & town -->
          <div class="mb-4 form-group">
            <label class="flex items-center mb-2">
              <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.prefecture_municipality }}</span>
              <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
            </label>
            <div class="flex -mx-2">
              <div class="w-full px-2">
                <span
                  class="block form-element px-2 r:py-[15px] xs:px-[15px] r:min-h-[56px] leading-[1.53]"
                  :class="[
                    v$.form.prefecture.$error || v$.form.municipality.$error || v$.form.town.$error || fieldLengthErr.totalAddress ? 'form-invalid' : '',
                    v$.form.prefecture.$dirty || v$.form.municipality.$dirty || v$.form.town.$dirty ? 'form-dirty' : '',
                    fieldServerErr.prefecture || fieldServerErr.municipality || fieldServerErr.town ? 'form-invalid-server' : '',
                    !!form.prefecture && !!form.municipality ? '' : 'text-neutral-gray-300'
                  ]"
                >
                  {{prefectureCityTownVal}}
                </span>
              </div>
              <text-input
                v-model="form.prefecture"
                class="wrap-hidden"
                type="hidden"
                id="user_prefecture"
                name="user[prefecture]"
                data-group-length="total-address"
                :customClass="`${v$.form.prefecture.$error || fieldLengthErr.totalAddress ? 'form-invalid' : ''} ${v$.form.prefecture.$dirty ? 'form-dirty' : ''} ${fieldServerErr.prefecture ? 'form-invalid-server' : ''}`"
              />
              <text-input
                v-model="form.municipality"
                class="wrap-hidden"
                type="hidden"
                id="user_municipality"
                name="user[municipality]"
                data-group-length="total-address"
                :customClass="`${v$.form.municipality.$error || fieldLengthErr.totalAddress ? 'form-invalid' : ''} ${v$.form.municipality.$dirty ? 'form-dirty' : ''} ${fieldServerErr.municipality ? 'form-invalid-server' : ''}`"
              />
              <text-input
                v-model="form.town"
                class="wrap-hidden"
                type="hidden"
                id="user_town"
                name="user[town]"
                data-group-length="total-address"
                :customClass="`${v$.form.town.$error || fieldLengthErr.totalAddress ? 'form-invalid' : ''} ${v$.form.town.$dirty ? 'form-dirty' : ''} ${fieldServerErr.town ? 'form-invalid-server' : ''}`"
              />
            </div>
          </div>
          <!-- detail address -->
          <div class="mb-4 form-group">
            <label class="flex items-center mb-2">
              <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.detail_address }}</span>
              <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
            </label>
            <div class="flex mb-2 -mx-2">
              <div class="w-full px-2">
                <text-input
                  v-model="form.detail_address"
                  class="w-full"
                  id="user_detail_address"
                  name="user[detail_address]"
                  :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.detail_address"
                  data-group-length="total-address"
                  :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.form.detail_address.$error || fieldLengthErr.totalAddress ? 'form-invalid' : ''} ${v$.form.detail_address.$dirty ? 'form-dirty' : ''} ${fieldServerErr.detail_address ? 'form-invalid-server' : ''}`"
                  maxlength="20"
                  @blur="handleCheckLength('total-address')"
                  @input="handleSetTouch('detail_address'); handleCheckServerError('user_detail_address', ['detail_address'])"
                />
              </div>
            </div>
          </div>
          <!-- address -->
          <div class="mb-4 form-group">
            <label class="flex items-center mb-2">
              <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.address }}</span>
            </label>
            <div class="flex -mx-2">
              <div class="w-full px-2">
                <text-input
                  v-model="form.address"
                  class="w-full"
                  id="user_address"
                  name="user[address]"
                  :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.address"
                  data-group-length="total-address"
                  :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${fieldLengthErr.totalAddress ? 'form-invalid' : ''} ${fieldServerErr.address ? 'form-invalid-server' : ''}`"
                  maxlength="20"
                  @blur="handleCheckLength('total-address')"
                  @input="handleCheckServerError('user_address', ['address'])"
                />
              </div>
            </div>
            <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="postal_code" v-if="fieldLengthErr.totalAddress || v$.form.prefecture.$error || v$.form.municipality.$error || v$.form.town.$error || v$.form.detail_address.$error || fieldServerErr.prefecture || fieldServerErr.municipality || fieldServerErr.town || fieldServerErr.detail_address || fieldServerErr.address">
              <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.full_address }}</span>
            </label>
          </div>
          <!-- phone -->
          <div class="form-group">
            <label class="flex items-center mb-2">
              <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.phone }}</span>
              <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
            </label>
            <div class="flex mb-2 -mx-2">
              <div class="relative w-full px-2">
                <text-input
                  v-model="form.phone"
                  class="w-full"
                  id="user_phone"
                  name="user[phone]"
                  :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.phone"
                  data-mask-blocks="[3,4,4]"
                  data-mask-delimiter="-"
                  type="tel"
                  :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.form.phone.$error ? 'form-invalid' : ''} ${v$.form.phone.$dirty ? 'form-dirty' : ''} ${fieldServerErr.phone ? 'form-invalid-server' : ''}`"
                  @change="handleSetTouch('phone'); handleCheckServerError('user_phone', ['phone'])"
                  @input="getMaskValue($event, 'phone', 13)"
                />
              </div>
            </div>
            <span class="block text-right r:text-[14px] leading-[1.53] r:tracking-[0.25px] text-neutral-gray-400">{{ viewsJa.contracts.blocks.customer_information.fields_note.phone }}</span>
            <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.form.phone.$error || fieldServerErr.phone">
              <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.phone }}</span>
            </label>
          </div>
        </div>
      </div>

      <!-- parent info -->
      <div class="parent-section r:max-w-[672px] mx-auto">
        <h2 class="r:text-[24px] leading-[1.53] r:tracking-[0.25px] px-6 mb-4" v-html="viewsJa.contracts.blocks.customer_information.groups.parent_information.group_title"></h2>
        <div v-if="!!formErrors && !!formErrors.form_field && formErrors.form_field['male.contract'] !== undefined" class="px-4 py-6 bg-white border-b father-info xs:px-6 xs:py-8 border-neutral-gray-100">
          <h3 class="r:text-[18px] text-primary-600 leading-[1.53] r:tracking-[0.25px] mb-6 xs:mb-8">{{ viewsJa.contracts.blocks.customer_information.groups.parent_information.father_section }}</h3>
          <div class="px-4 py-3 border rounded-lg border-tw-red-200 bg-tw-red-50 xs:px-6 xs:py-4">
            <svg class="mb-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8474 3.31557L1.5381 19.3293C1.02136 20.2181 1.66264 21.3327 2.6908 21.3327H21.3094C22.3376 21.3327 22.9788 20.2181 22.4621 19.3293L13.1528 3.31557C12.6387 2.43127 11.3615 2.43127 10.8474 3.31557ZM13.3337 12.666C13.3337 13.4024 12.7368 13.9994 12.0004 13.9994C11.264 13.9994 10.6671 13.4024 10.6671 12.666V8.66604C10.6671 7.92966 11.264 7.3327 12.0004 7.3327C12.7368 7.3327 13.3337 7.92966 13.3337 8.66604V12.666ZM10.3337 16.9994C10.3337 16.0789 11.0799 15.3327 12.0004 15.3327C12.9209 15.3327 13.6671 16.0789 13.6671 16.9994C13.6671 17.9198 12.9209 18.666 12.0004 18.666C11.0799 18.666 10.3337 17.9198 10.3337 16.9994Z" fill="#E33B24"/>
            </svg>
            <h4 class="r:text-[14px] font-bold leading-[1.53] r:tracking-[0.25px] text-tw-red-600">{{ viewsJa.contracts.blocks.customer_information.fields_note.parent.exist_contract.male.title }}</h4>
          </div>
        </div>
        <div v-if="!!formErrors && !!formErrors.form_field && formErrors.form_field['female.contract'] !== undefined" class="px-4 py-6 bg-white border-b mother-info xs:px-6 xs:py-8 border-neutral-gray-100">
          <h3 class="r:text-[18px] text-primary-600 leading-[1.53] r:tracking-[0.25px] mb-6 xs:mb-8">{{ viewsJa.contracts.blocks.customer_information.groups.parent_information.mother_section }}</h3>
          <div class="px-4 py-3 border rounded-lg border-tw-red-200 bg-tw-red-50 xs:px-6 xs:py-4">
            <svg class="mb-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8474 3.31557L1.5381 19.3293C1.02136 20.2181 1.66264 21.3327 2.6908 21.3327H21.3094C22.3376 21.3327 22.9788 20.2181 22.4621 19.3293L13.1528 3.31557C12.6387 2.43127 11.3615 2.43127 10.8474 3.31557ZM13.3337 12.666C13.3337 13.4024 12.7368 13.9994 12.0004 13.9994C11.264 13.9994 10.6671 13.4024 10.6671 12.666V8.66604C10.6671 7.92966 11.264 7.3327 12.0004 7.3327C12.7368 7.3327 13.3337 7.92966 13.3337 8.66604V12.666ZM10.3337 16.9994C10.3337 16.0789 11.0799 15.3327 12.0004 15.3327C12.9209 15.3327 13.6671 16.0789 13.6671 16.9994C13.6671 17.9198 12.9209 18.666 12.0004 18.666C11.0799 18.666 10.3337 17.9198 10.3337 16.9994Z" fill="#E33B24"/>
            </svg>
            <h4 class="r:text-[14px] font-bold leading-[1.53] r:tracking-[0.25px] text-tw-red-600">{{ viewsJa.contracts.blocks.customer_information.fields_note.parent.exist_contract.female.title }}</h4>
          </div>
        </div>
        <template v-for="relative in relativesData" :key="relative.id">
          <template v-if="relative.gender == 'male'">
            <div class="px-4 py-6 bg-white border-b father-info xs:px-6 xs:py-8 border-neutral-gray-100">
              <h3 class="r:text-[18px] text-primary-600 leading-[1.53] r:tracking-[0.25px] mb-6 xs:mb-8">{{ viewsJa.contracts.blocks.customer_information.groups.parent_information.father_section }}</h3>
              <!-- full name father -->
              <div class="mb-4 form-group">
                <label class="flex items-center mb-2">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.full_name_father }}</span>
                  <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
                </label>
                <div class="flex -mx-0.5">
                  <div class="w-1/2 px-0.5">
                    <text-input
                      v-model="form.lastNameFather"
                      class="w-full"
                      id="user_relative_attributes_0_last_name"
                      name="user[relative_attributes][0][last_name]"
                      :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.last_name_father"
                      data-group-length="full-name-father"
                      :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.form.lastNameFather.$error ? 'form-invalid' : ''} ${v$.form.lastNameFather.$dirty ? 'form-dirty' : ''} ${fieldServerErr['male.last_name'] || fieldServerErr['male.kanji'] ? 'form-invalid-server' : ''}`"
                      @change="handleSetTouch('lastNameFather'); handleCheckServerError('user_relative_attributes_0_last_name', ['male.last_name', 'male.kanji']); setTouchParallel('lastNameFatherKana', 'lastNameFather')"
                      @blur="handleCheckLength('father')"
                      @input="setKana($event, 'lastNameFather', 'lastNameFatherKana');"
                    />
                  </div>
                  <div class="w-1/2 px-0.5">
                    <text-input
                      v-model="form.firstNameFather"
                      class="w-full"
                      id="user_relative_attributes_0_first_name"
                      name="user[relative_attributes][0][first_name]"
                      :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.first_name_father"
                      data-group-length="full-name-father"
                      :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.form.firstNameFather.$error ? 'form-invalid' : ''} ${v$.form.firstNameFather.$dirty ? 'form-dirty' : ''} ${fieldServerErr['male.first_name'] || fieldServerErr['male.kanji'] ? 'form-invalid-server' : ''}`"
                      @change="handleSetTouch('firstNameFather'); handleCheckServerError('user_relative_attributes_0_first_name', ['male.first_name', 'male.kanji']); setTouchParallel('firstNameFatherKana', 'firstNameFather')"
                      @blur="handleCheckLength('father')"
                      @input="setKana($event, 'firstNameFather', 'firstNameFatherKana');"
                    />
                  </div>
                </div>
                <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.form.lastNameFather.$error || v$.form.firstNameFather.$error || fieldLengthErr.fullNameFather || fieldServerErr['male.last_name'] || fieldServerErr['male.first_name'] || fieldServerErr['male.kanji']">
                  <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.full_name_father }}</span>
                </label>
              </div>
              <!-- full name kana father -->
              <div class="mb-4 form-group">
                <label class="flex items-center mb-2">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.full_name_kana_father }}</span>
                  <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
                </label>
                <div class="flex -mx-0.5">
                  <div class="w-1/2 px-0.5">
                    <text-input
                      v-model="form.lastNameFatherKana"
                      class="w-full"
                      id="user_relative_attributes_0_last_name_kana"
                      name="user[relative_attributes][0][last_name_kana]"
                      :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.last_name_kana_father"
                      data-group-length="full-name-kana-father"
                      :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.form.lastNameFatherKana.$error || fieldLengthErr.fullNameFatherKana ? 'form-invalid' : ''} ${v$.form.lastNameFatherKana.$dirty ? 'form-dirty' : ''} ${fieldServerErr['male.last_name_kana'] || fieldServerErr['male.kana'] ? 'form-invalid-server' : ''}`"
                      @change="handleSetTouch('lastNameFatherKana'); handleCheckServerError('user_relative_attributes_0_last_name_kana', ['male.last_name_kana', 'male.kana'])"
                      @blur="handleCheckLength('father-kana')"
                    />
                  </div>
                  <div class="w-1/2 px-0.5">
                    <text-input
                      v-model="form.firstNameFatherKana"
                      class="w-full"
                      id="user_relative_attributes_0_first_name_kana"
                      name="user[relative_attributes][0][first_name_kana]"
                      :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.first_name_kana_father"
                      data-group-length="full-name-kana-father"
                      :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.form.firstNameFatherKana.$error || fieldLengthErr.fullNameFatherKana ? 'form-invalid' : ''} ${v$.form.firstNameFatherKana.$dirty ? 'form-dirty' : ''} ${fieldServerErr['male.first_name_kana'] || fieldServerErr['male.kana'] ? 'form-invalid-server' : ''}`"
                      @change="handleSetTouch('firstNameFatherKana'); handleCheckServerError('user_relative_attributes_0_first_name_kana', ['male.first_name_kana', 'male.kana'])"
                      @blur="handleCheckLength('father-kana')"
                    />
                  </div>
                </div>
                <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.form.lastNameFatherKana.$error || v$.form.firstNameFatherKana.$error || fieldLengthErr.fullNameFatherKana || fieldServerErr['male.last_name_kana'] || fieldServerErr['male.first_name_kana'] || fieldServerErr['male.kana']">
                  <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.full_name_kana_father }}</span>
                </label>
              </div>
              <!-- father personal info -->
              <div class="form-group" :class="{'mb-6' : Object.keys(relativesData).length > 1}">
                <div class="flex items-start mb-4">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] r:min-w-[72px] xs:r:min-w-[130px]">{{ viewsJa.contracts.blocks.customer_information.fields_note.parent.dob }}</span>
                  <span class="flex-1 ml-6">{{ relative.dob }}</span>
                </div>
                <div class="flex items-start mb-4">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] r:min-w-[72px] xs:r:min-w-[130px]">{{ viewsJa.contracts.blocks.customer_information.fields_note.parent.age }}</span>
                  <span class="flex-1 ml-6">
                    <span class="block mb-0.5">{{ relative.age }}</span>
                    <tippy
                      interactive
                      animation="fade"
                      trigger="click"
                      arrow="false"
                      :maxWidth="280"
                      :onShown="(instance) => {
                        handleCloseTooltip(instance);
                      }"
                    >
                      <template #default>
                        <a href="javascript:;" id="show-tooltip-contract-day-male" data-testid="show-tooltip-contract-day-male" class="inline-flex items-center transition text-tw-blue-500 hover:opacity-70">
                          <svg id="show-tooltip-contract-day-male-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                            <path id="show-tooltip-contract-day-male-path" fill-rule="evenodd" clip-rule="evenodd" d="M9 17.5C13.4183 17.5 17 13.9183 17 9.5C17 5.08172 13.4183 1.5 9 1.5C4.58172 1.5 1 5.08172 1 9.5C1 13.9183 4.58172 17.5 9 17.5ZM11.3025 8.6875L10.6275 9.3775C10.5706 9.43516 10.516 9.48899 10.4638 9.54042C10.0201 9.97739 9.75 10.2435 9.75 11.25C9.75 11.6642 9.41421 12 9 12C8.58579 12 8.25 11.6642 8.25 11.25V11.125C8.25 10.3 8.5875 9.55 9.1275 9.0025L10.0575 8.0575C10.335 7.7875 10.5 7.4125 10.5 7C10.5 6.175 9.825 5.5 9 5.5C8.175 5.5 7.5 6.175 7.5 7C7.5 7.41421 7.16421 7.75 6.75 7.75C6.33579 7.75 6 7.41421 6 7C6 5.3425 7.3425 4 9 4C10.6575 4 12 5.3425 12 7C12 7.66 11.73 8.26 11.3025 8.6875ZM10 14C10 14.5523 9.55229 15 9 15C8.44771 15 8 14.5523 8 14C8 13.4477 8.44771 13 9 13C9.55229 13 10 13.4477 10 14Z" fill="#2097E5"/>
                          </svg>
                          <span id="show-tooltip-contract-day-male-span" class="ml-1 r:text-[14px] leading-[1.53] r:tracking-[0.25px]">{{ convertJaParams(viewsJa.contracts.blocks.customer_information.fields_note.parent.date_valid_for_contract, {date_valid: contractStartDay}) }}</span>
                        </a>
                      </template>
                      <template #content>
                        <div class="p-3 rounded-lg bg-neutral-gray-700 xs:p-4">
                          <h4 class="text-white r:text-[16px] font-black leading-[1.53] r:tracking-[0.25px] mb-3 xs:mb-4">{{ convertJaParams(viewsJa.contracts.blocks.customer_information.fields_tooltip.parent.date_valid_for_contract.title, {date_valid: contractStartDay}) }}</h4>
                          <p class="text-white r:text-[14px] leading-[1.53] r:tracking-[0.25px] mb-6 xs:mb-8">{{ viewsJa.contracts.blocks.customer_information.fields_tooltip.parent.date_valid_for_contract.desc }}</p>
                          <button type="button" data-testid="open-tooltip-custom-info" id="open-tooltip-custom-info" class="btn-close-tooltip btn-solid inline-flex items-center justify-center rounded-full btn-gradient r:text-[14px] py-3.5 px-4 font-medium text-white shadow-gray-1 bg-neutral-gray-800 r:tracking-[0.25px] leading-[1.33] r:min-h-[47px]">
                            <span id="open-tooltip-custom-info-span">{{ viewsJa.contracts.blocks.customer_information.fields_tooltip.btn_close }}</span>
                          </button>
                        </div>
                      </template>
                    </tippy>
                  </span>
                </div>
                <div class="flex items-start">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] r:min-w-[72px] xs:r:min-w-[130px]">{{ viewsJa.contracts.blocks.customer_information.fields_note.parent.gender }}</span>
                  <span class="flex-1 ml-6">{{ viewsJa.contracts.blocks.customer_information.fields.gender[relative.gender] }}</span>
                </div>
              </div>
            </div>
          </template>
          <template v-if="relative.gender == 'female'">
            <div class="px-4 py-6 bg-white border-b mother-info xs:px-6 xs:py-8 border-neutral-gray-100">
              <h3 class="r:text-[18px] text-primary-600 leading-[1.53] r:tracking-[0.25px] mb-6 xs:mb-8">{{ viewsJa.contracts.blocks.customer_information.groups.parent_information.mother_section }}</h3>
              <!-- full name mother -->
              <div class="mb-4 form-group">
                <label class="flex items-center mb-2">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.full_name_mother }}</span>
                  <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
                </label>
                <div class="flex -mx-0.5">
                  <div class="w-1/2 px-0.5">
                    <text-input
                      v-model="form.lastNameMother"
                      class="w-full"
                      id="user_relative_attributes_1_last_name"
                      name="user[relative_attributes][1][last_name]"
                      :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.last_name_mother"
                      data-group-length="full-name-mother"
                      :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.form.lastNameMother.$error || fieldLengthErr.fullNameMotherKana ? 'form-invalid' : ''} ${v$.form.lastNameMother.$dirty ? 'form-dirty' : ''} ${fieldServerErr['female.last_name'] || fieldServerErr['female.kanji'] ? 'form-invalid-server' : ''}`"
                      @change="handleSetTouch('lastNameMother'); handleCheckServerError('user_relative_attributes_1_last_name', ['female.last_name', 'female.kanji']); setTouchParallel('lastNameMotherKana', 'lastNameMother')"
                      @blur="handleCheckLength('mother')"
                      @input="setKana($event, 'lastNameMother', 'lastNameMotherKana');"
                    />
                  </div>
                  <div class="w-1/2 px-0.5">
                    <text-input
                      v-model="form.firstNameMother"
                      class="w-full"
                      id="user_relative_attributes_1_first_name"
                      name="user[relative_attributes][1][first_name]"
                      :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.first_name_mother"
                      data-group-length="full-name-mother"
                      :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.form.firstNameMother.$error || fieldLengthErr.fullNameMotherKana ? 'form-invalid' : ''} ${v$.form.firstNameMother.$dirty ? 'form-dirty' : ''} ${fieldServerErr['female.first_name'] || fieldServerErr['female.kanji'] ? 'form-invalid-server' : ''}`"
                      @change="handleSetTouch('firstNameMother'); handleCheckServerError('user_relative_attributes_1_first_name', ['female.first_name', 'female.kanji']); setTouchParallel('firstNameMotherKana', 'firstNameMother')"
                      @blur="handleCheckLength('mother')"
                      @input="setKana($event, 'firstNameMother', 'firstNameMotherKana');"
                    />
                  </div>
                </div>
                <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.form.lastNameMother.$error || v$.form.firstNameMother.$error || fieldLengthErr.fullNameMother || fieldServerErr['female.last_name'] || fieldServerErr['female.first_name'] || fieldServerErr['female.kanji']">
                  <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.full_name_mother }}</span>
                </label>
              </div>
              <!-- full name kana mother -->
              <div class="mb-4 form-group">
                <label class="flex items-center mb-2">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px]">{{ modelsJa.activerecord.attributes.contracts.full_name_kana_mother }}</span>
                  <span class="r:text-[10px] r:tracking-[0.25px] leading-[1.53] text-tw-red-500 bg-tw-red-50 rounded px-1 mt-1 ml-2">{{ viewsJa.contracts.required }}</span>
                </label>
                <div class="flex -mx-0.5">
                  <div class="w-1/2 px-0.5">
                    <text-input
                      v-model="form.lastNameMotherKana"
                      class="w-full"
                      id="user_relative_attributes_1_last_name_kana"
                      name="user[relative_attributes][1][last_name_kana]"
                      :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.last_name_kana_mother"
                      data-group-length="full-name-kana-mother"
                      :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.form.lastNameMotherKana.$error ? 'form-invalid' : ''} ${v$.form.lastNameMotherKana.$dirty ? 'form-dirty' : ''} ${fieldServerErr['female.last_name_kana'] || fieldServerErr['female.kana'] ? 'form-invalid-server' : ''}`"
                      @change="handleSetTouch('lastNameMotherKana'); handleCheckServerError('user_relative_attributes_1_last_name_kana', ['female.last_name_kana', 'female.kana'])"
                      @blur="handleCheckLength('mother-kana')"
                    />
                  </div>
                  <div class="w-1/2 px-0.5">
                    <text-input
                      v-model="form.firstNameMotherKana"
                      class="w-full"
                      id="user_relative_attributes_1_first_name_kana"
                      name="user[relative_attributes][1][first_name_kana]"
                      :placeholder="viewsJa.contracts.blocks.customer_information.placeholders.first_name_kana_mother"
                      data-group-length="full-name-kana-mother"
                      :customClass="`form-element px-2 r:py-[15px] xs:r:px-[15px] r:min-h-[56px] ${v$.form.firstNameMotherKana.$error ? 'form-invalid' : ''} ${v$.form.firstNameMotherKana.$dirty ? 'form-dirty' : ''} ${fieldServerErr['female.first_name_kana'] || fieldServerErr['female.kana'] ? 'form-invalid-server' : ''}`"
                      @change="handleSetTouch('firstNameMotherKana'); handleCheckServerError('user_relative_attributes_1_first_name_kana', ['female.first_name_kana', 'female.kana'])"
                      @blur="handleCheckLength('mother-kana')"
                    />
                  </div>
                </div>
                <label class="mt-2 flex text-tw-red-600 r:text-[14px] leading-[1]" for="last_name" v-if="v$.form.lastNameMotherKana.$error || v$.form.firstNameMotherKana.$error || fieldLengthErr.fullNameMotherKana || fieldServerErr['female.last_name_kana'] || fieldServerErr['female.first_name_kana'] || fieldServerErr['female.kana']">
                  <span class="flex-1 mt-0.5">{{ viewsJa.contracts.blocks.customer_information.errors.full_name_kana_mother }}</span>
                </label>
              </div>
              <!-- mother personal info -->
              <div class="form-group">
                <div class="flex items-start mb-4">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] r:min-w-[72px] xs:r:min-w-[130px]">{{ viewsJa.contracts.blocks.customer_information.fields_note.parent.dob }}</span>
                  <span class="flex-1 ml-6">{{ relative.dob }}</span>
                </div>
                <div class="flex items-start mb-4">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] r:min-w-[72px] xs:r:min-w-[130px]">{{ viewsJa.contracts.blocks.customer_information.fields_note.parent.age }}</span>
                  <span class="flex-1 ml-6">
                    <span class="block mb-0.5">{{ relative.age }}</span>
                    <tippy
                      interactive
                      animation="fade"
                      trigger="click"
                      arrow="false"
                      :maxWidth="280"
                      :onShown="(instance) => {
                        handleCloseTooltip(instance);
                      }"
                    >
                      <template #default>
                        <a href="javascript:;" id="show-tooltip-contract-day-female" data-testid="show-tooltip-contract-day-female" class="inline-flex items-center transition text-tw-blue-500 hover:opacity-70">
                          <svg id="show-tooltip-contract-day-female-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                            <path id="show-tooltip-contract-day-female-path" fill-rule="evenodd" clip-rule="evenodd" d="M9 17.5C13.4183 17.5 17 13.9183 17 9.5C17 5.08172 13.4183 1.5 9 1.5C4.58172 1.5 1 5.08172 1 9.5C1 13.9183 4.58172 17.5 9 17.5ZM11.3025 8.6875L10.6275 9.3775C10.5706 9.43516 10.516 9.48899 10.4638 9.54042C10.0201 9.97739 9.75 10.2435 9.75 11.25C9.75 11.6642 9.41421 12 9 12C8.58579 12 8.25 11.6642 8.25 11.25V11.125C8.25 10.3 8.5875 9.55 9.1275 9.0025L10.0575 8.0575C10.335 7.7875 10.5 7.4125 10.5 7C10.5 6.175 9.825 5.5 9 5.5C8.175 5.5 7.5 6.175 7.5 7C7.5 7.41421 7.16421 7.75 6.75 7.75C6.33579 7.75 6 7.41421 6 7C6 5.3425 7.3425 4 9 4C10.6575 4 12 5.3425 12 7C12 7.66 11.73 8.26 11.3025 8.6875ZM10 14C10 14.5523 9.55229 15 9 15C8.44771 15 8 14.5523 8 14C8 13.4477 8.44771 13 9 13C9.55229 13 10 13.4477 10 14Z" fill="#2097E5"/>
                          </svg>
                          <span id="tooltip-show-contract-day-span" class="ml-1 r:text-[14px] leading-[1.53] r:tracking-[0.25px]">{{ convertJaParams(viewsJa.contracts.blocks.customer_information.fields_note.parent.date_valid_for_contract, {date_valid: contractStartDay}) }}</span>
                        </a>
                      </template>
                      <template #content>
                        <div class="p-3 rounded-lg bg-neutral-gray-700 xs:p-4">
                          <h4 class="text-white r:text-[16px] font-black leading-[1.53] r:tracking-[0.25px] mb-3 xs:mb-4">{{ convertJaParams(viewsJa.contracts.blocks.customer_information.fields_tooltip.parent.date_valid_for_contract.title, {date_valid: contractStartDay}) }}</h4>
                          <p class="text-white r:text-[14px] leading-[1.53] r:tracking-[0.25px] mb-6 xs:mb-8">{{ viewsJa.contracts.blocks.customer_information.fields_tooltip.parent.date_valid_for_contract.desc }}</p>
                          <button type="button" data-testid="close-tooltip-custom-info" id="close-tooltip-custom-info"  class="btn-close-tooltip btn-solid inline-flex items-center justify-center rounded-full btn-gradient r:text-[14px] py-3.5 px-4 font-medium text-white shadow-gray-1 bg-neutral-gray-800 r:tracking-[0.25px] leading-[1.33] r:min-h-[47px]">
                            <span id="close-tooltip-custom-info-span">{{ viewsJa.contracts.blocks.customer_information.fields_tooltip.btn_close }}</span>
                          </button>
                        </div>
                      </template>
                    </tippy>
                  </span>
                </div>
                <div class="flex items-start">
                  <span class="r:text-[16px] leading-[1.53] r:tracking-[0.25px] r:min-w-[72px] xs:r:min-w-[130px]">{{ viewsJa.contracts.blocks.customer_information.fields_note.parent.gender }}</span>
                  <span class="flex-1 ml-6">{{ viewsJa.contracts.blocks.customer_information.fields.gender[relative.gender] }}</span>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>

      <div class="application-remind r:max-w-[672px] mx-auto mb-16">
        <div class="px-4 pt-6 xs:px-6 xs:pt-8">
          <div class="px-4 py-4 border rounded-lg border-neutral-gray-200 bg-neutral-gray-50 xs:px-6 xs:pb-6">
            <svg class="mb-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9997 22.6663C17.8907 22.6663 22.6663 17.8907 22.6663 11.9997C22.6663 6.10864 17.8907 1.33301 11.9997 1.33301C6.10864 1.33301 1.33301 6.10864 1.33301 11.9997C1.33301 17.8907 6.10864 22.6663 11.9997 22.6663ZM13.6663 6.99967C13.6663 7.92015 12.9201 8.66634 11.9997 8.66634C11.0792 8.66634 10.333 7.92015 10.333 6.99967C10.333 6.0792 11.0792 5.33301 11.9997 5.33301C12.9201 5.33301 13.6663 6.0792 13.6663 6.99967ZM10.6663 11.9997C10.6663 11.2633 11.2633 10.6663 11.9997 10.6663C12.7361 10.6663 13.333 11.2633 13.333 11.9997V17.333C13.333 18.0694 12.7361 18.6663 11.9997 18.6663C11.2633 18.6663 10.6663 18.0694 10.6663 17.333V11.9997Z" fill="#93A3B5"/>
            </svg>
            <h4 class="r:text-[14px] font-bold leading-[1.53] r:tracking-[0.25px] mb-1">{{ viewsJa.contracts.blocks.customer_information.remind_box.title }}</h4>
            <p class="r:text-[14px] leading-[1.53] r:trackin-[0.25px] mb-4">{{ viewsJa.contracts.blocks.customer_information.remind_box.desc }}</p>
            <a id="open-quotation-modal" class="r:text-[14px] text-tw-blue-500 hover:opacity-70 transition r:tracking-[0.25px] leading-[1.57] underline cursor-pointer" @click.prevent="setQuotationOpen(true)">{{ viewsJa.contracts.blocks.customer_information.remind_box.back_to_quote }}</a>
            <QuotationModal
              :careType="careType"
              :father="father"
              :mother="mother"
              :isFirstTime="isFirstTime"
              :isQuotationOpen="isQuotationOpen"
              :setQuotationOpen="setQuotationOpen"
              :contractStartDayStr = "contractStartDayStr"
            />
          </div>
        </div>
      </div>

      <div class="application-action r:max-w-[672px] mx-auto">
        <div class="px-4 xs:px-6">
          <div class="flex flex-col items-center form-group">
            <span v-if="cantSubmitForm" class="text-tw-red-500 r:text-[14px] leading-[1.53] r:tracking-[0.25px] mb-2">{{ viewsJa.contracts.blocks.customer_information.not_complete_form }}</span>
            <button type="button" data-testid="submit-application-form-step-1" id="submit-application-form-step-1" :disabled="cantSubmitForm" class="mb-6 inline-flex items-center justify-center rounded-full btn-gradient btn-primary-gradient-horizontal r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:r:px-[18px] xs:py-4 font-medium text-white shadow-gray-1 leading-[1.33] r:min-h-[53px]" @click.prevent="saveFormData">
              <span id="submit-application-form-step-1-span" class="mr-2">{{ viewsJa.contracts.blocks.customer_information.btn.next }}</span>
              <svg id="submit-application-form-step-1-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                <path id="submit-application-form-step-1-path" fill-rule="evenodd" clip-rule="evenodd" d="M6.32036 3.76646C6.72549 3.3911 7.35819 3.41524 7.73355 3.82036L12.9958 9.50001L7.73355 15.1796C7.35819 15.5848 6.72549 15.6089 6.32036 15.2335C5.91524 14.8582 5.8911 14.2255 6.26646 13.8204L10.2693 9.50001L6.26646 5.17965C5.8911 4.77452 5.91524 4.14182 6.32036 3.76646Z" :fill="cantSubmitForm ? '#D3DDE8' : '#FFFFFF'"/>
              </svg>
            </button>
            <a :href="$routes.result_quotations()" data-testid="back-quotation-from-step-1" id="back-quotation-from-step-1" class="btn-solid inline-flex items-center justify-center rounded-full btn-gradient r:text-[14px] xs:r:text-[16px] py-2 px-2 xs:px-4 xs:py-4 text-white bg-neutral-gray-400 leading-[1.33] r:min-h-[53px] r:tracking-[0.25px] shadow-gray-1">
              <svg id="back-quotation-from-step-1-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                <path id="back-quotation-from-step-1-path" fill-rule="evenodd" clip-rule="evenodd" d="M11.6796 15.2335C11.2745 15.6089 10.6418 15.5848 10.2665 15.1796L5.00417 9.49999L10.2665 3.82035C10.6418 3.41523 11.2745 3.3911 11.6796 3.76645C12.0848 4.14181 12.1089 4.77451 11.7335 5.17964L7.73066 9.49999L11.7335 13.8204C12.1089 14.2255 12.0848 14.8582 11.6796 15.2335Z" fill="white"/>
              </svg>
              <span id="back-quotation-from-step-1-span" class="ml-2">{{ viewsJa.contracts.blocks.customer_information.btn.prev }}</span>
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
:deep(.tippy-content) {
  padding: 0;
}
</style>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength, maxLength } from '@vuelidate/validators';
import { hiraganaKanjiKana, katakanaOnly, phone } from '@/utils/custom-validators';
import { convertJaParams } from '@/utils/utils';
import TextInput from '@/Shared/TextInput.vue';
import RadioInput from '@/Shared/RadioInput.vue';
import DropdownDate from '@/Shared/User/DropdownDate.vue';
import Places from '@/Shared/User/Places.vue';
import IconRawSvg from '@/Shared/IconRawSvg.vue';
import * as AutoKana from 'vanilla-autokana';
import { setMask, setLimitLength } from '@/utils/utils';
import { Tippy } from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import QuotationModal from '@/Pages/Quotations/Components/QuotationModal.vue';

let autoKanaNameField = {
  lastName: null,
  firstName: null,
  lastNameFather: null,
  firstNameFather: null,
  lastNameMother: null,
  firstNameMother: null,
}

export default {
  components: {
    TextInput,
    RadioInput,
    IconRawSvg,
    DropdownDate,
    Places,
    Tippy,
    QuotationModal,
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    pageStep: {
      type: Number,
      required: true
    },
    relatives: {
      type: Array,
      required: false
    },
    contractStartDay: {
      type: String,
      required: true
    },
    userInfo: {
      type: Object,
      required: true
    },
    isLogged: {
      type: Boolean,
      required: true
    },
    formErrors: {
      type: Object,
      required: false
    },
    quotation: {
      type: Object,
      required: true
    },
    quotationData: {
      type: Object,
      required: true
    }
  },
  emits: ['set-step'],
  expose: ['checkFormError'],
  data () {
    return {
      convertJaParams: convertJaParams,
      modelsJa: this.jaConfig.modelsJa,
      viewsJa: this.jaConfig.viewsJa,
      isValid: true,
      userBirthYear: null,
      cantSubmitForm: true,
      careType: this.quotation.careType,
      father: this.quotation.father,
      mother: this.quotation.mother,
      isFirstTime: this.quotation.isFirstTime,
      contractStartDayStr: this.quotation.contractStartDayStr,
      form: {
        firstName: '',
        lastName: '',
        firstNameKana: '',
        lastNameKana: '',
        gender: null,
        phone: null,
        email: '',
        dateOfBirth: '',
        postalCode: '',
        prefecture: '',
        municipality: '',
        town: '',
        detail_address: '',
        address: '',
        firstNameFather: '',
        lastNameFather: '',
        firstNameFatherKana: '',
        lastNameFatherKana: '',
        firstNameMother: '',
        lastNameMother: '',
        firstNameMotherKana: '',
        lastNameMotherKana: '',
      },
      dropdownCls: {
        year: 'mb-2 xs:mb-0 w-full xs:w-1/3 px-0.5',
        month: 'w-1/2 xs:w-1/3 px-0.5',
        day: 'w-1/2 xs:w-1/3 px-0.5',
      },
      postalCodeErr: false,
      prefectureCityTownVal: this.jaConfig.viewsJa.contracts.blocks.customer_information.placeholders.prefecture_municipality,
      formItemExist: [],
      relativesData: {},
      fieldLengthErr: {
        fullNameKana: false,
        fullNameFather: false,
        fullNameFatherKana: false,
        fullNameMother: false,
        fullNameMotherKana: false,
        totalAddress: false,
      },
      fieldServerErr: {
        last_name: false,
        first_name: false,
        last_name_kana: false,
        first_name_kana: false,
        fullname_kana: false,
        date_of_birth: false,
        phone: false,
        register_email: false,
        postal_code: false,
        prefecture: false,
        municipality: false,
        town: false,
        detail_address: false,
        address: false,
        'male.kanji': false,
        'male.kana': false,
        'male.last_name': false,
        'male.first_name': false,
        'male.last_name_kana': false,
        'male.first_name_kana': false,
        'female.kanji': false,
        'female.kana': false,
        'female.last_name': false,
        'female.first_name': false,
        'female.last_name_kana': false,
        'female.first_name_kana': false,
      },
      notFirstLoadForm: JSON.parse(localStorage.getItem('notFirstLoadForm')),
      authenToken: document.querySelector('meta[name="csrf-token"]').content,
      isQuotationOpen: false,
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  created() {
    this.relativesData = {...this.relatives};
    if (!!this.formErrors &&
        !!this.formErrors.form_field &&
        (!!this.formErrors.form_field['male.contract'] !== undefined || this.formErrors.form_field['female.contract'] !== undefined)) {
      const preFormData = JSON.parse(localStorage.getItem('userForm'));
      if (!!preFormData) {
        for (let rawR in this.relativesData) {
          for (let r in preFormData.user.relatives_attributes) {
            if (this.relativesData[rawR].gender === preFormData.user.relatives_attributes[r].gender) {
              this.relativesData[rawR] = {...preFormData.user.relatives_attributes[r]};
            }
          }
        }
        preFormData.user.relatives_attributes = {...this.relativesData};
        localStorage.setItem('userForm', JSON.stringify(preFormData));
      };
    }
    this.getStoredData();
  },
  mounted() {
    this.checkFormError();
    localStorage.removeItem('ignoreParent');

    autoKanaNameField.lastName = AutoKana.bind('#user_last_name', '#user_last_name_kana', { katakana: true });
    autoKanaNameField.firstName = AutoKana.bind('#user_first_name', '#user_first_name_kana', { katakana: true });
    for (let i in this.relativesData) {
      if (this.relativesData[i].gender == 'male') {
        autoKanaNameField.lastNameFather = AutoKana.bind('#user_relative_attributes_0_last_name', '#user_relative_attributes_0_last_name_kana', { katakana: true });
        autoKanaNameField.firstNameFather = AutoKana.bind('#user_relative_attributes_0_first_name', '#user_relative_attributes_0_first_name_kana', { katakana: true });
      }
      if (this.relativesData[i].gender == 'female') {
        autoKanaNameField.lastNameMother = AutoKana.bind('#user_relative_attributes_1_last_name', '#user_relative_attributes_1_last_name_kana', { katakana: true });
        autoKanaNameField.firstNameMother = AutoKana.bind('#user_relative_attributes_1_first_name', '#user_relative_attributes_1_first_name_kana', { katakana: true });
      }
    }
  },
  methods: {
    async saveFormData (e) {
      e.preventDefault();
      this.isValid = await this.v$.$validate();
      if (this.isValid) {
        this.setStep(this.pageStep + 1);
        localStorage.setItem('userForm', JSON.stringify(this.generateFormData(this.form)));
        localStorage.setItem('notFirstLoadForm', true);
      } else {
        // focus on first field has error
        document.querySelectorAll('.form-invalid')[0].focus();
      }
    },
    handleSetTouch (field) {
      this.v$.form[field].$touch();
    },
    setStep (step) {
      this.$emit('set-step', step);
      localStorage.setItem('userFormStep', step);
    },
    checkFormError () {
      if (!!this.formErrors && this.pageStep == 1) {
        if (JSON.parse(localStorage.getItem('formErrors'))) {
          if (!!this.formErrors.form_field && Object.keys(this.formErrors.form_field).length) {
            for (let f in this.formErrors.form_field) {
              this.fieldServerErr[f] = true;
              if (f == 'male.contract' || f == 'female.contract') continue;
              delete this.formErrors[f];
            }
            if (!Object.keys(this.formErrors.form_field).length) {
              localStorage.setItem('formErrors', false);
            }
          }
        }
      }
    },
    getStoredData () {
      const quotationChange = JSON.parse(localStorage.getItem('quotationChange'));
      const isUseUserData = Object.keys(this.userInfo).length && !this.notFirstLoadForm ? true : false;
      const sessionData = JSON.parse(localStorage.getItem('userForm'));
      let storedData = !!sessionData ? sessionData.user : sessionData;
      if (isUseUserData) {
        storedData = this.userInfo;
      }
      if (storedData) {
        if (!!storedData.birth_year && !storedData.date_of_birth) {
          this.userBirthYear = storedData.birth_year;
        }
        if (quotationChange) {
          const relativeSvLength = Object.keys(this.relativesData).length;
          const relativeClLength = Object.keys(sessionData.user.relatives_attributes).length;
          if (relativeSvLength > relativeClLength) {
            for (let i in sessionData.user.relatives_attributes) {
              for (let j in this.relativesData) {
                if (sessionData.user.relatives_attributes[i].gender === this.relativesData[j].gender) {
                  this.relativesData[j].first_name = sessionData.user.relatives_attributes[i].first_name
                  this.relativesData[j].last_name = sessionData.user.relatives_attributes[i].last_name
                  this.relativesData[j].first_name_kana = sessionData.user.relatives_attributes[i].first_name_kana
                  this.relativesData[j].last_name_kana = sessionData.user.relatives_attributes[i].last_name_kana
                }
              }
            }
            // move key of female to 1
            for (let s in sessionData.user.relatives_attributes) {
              if (sessionData.user.relatives_attributes[s].gender == 'female') {
                sessionData.user.relatives_attributes['1'] = {...sessionData.user.relatives_attributes[s]};
                delete sessionData.user.relatives_attributes[s];
              }
            }
            sessionData.user.relatives_attributes['0'] = {...this.relativesData['0']};
            localStorage.setItem('userForm', JSON.stringify(sessionData))
          } else if (relativeSvLength < relativeClLength) {
            for (let m in this.relativesData) {
              for (let n in sessionData.user.relatives_attributes) {
                if (this.relativesData[m].gender === sessionData.user.relatives_attributes[n].gender) {
                  this.relativesData[m].first_name = sessionData.user.relatives_attributes[n].first_name
                  this.relativesData[m].last_name = sessionData.user.relatives_attributes[n].last_name
                  this.relativesData[m].first_name_kana = sessionData.user.relatives_attributes[n].first_name_kana
                  this.relativesData[m].last_name_kana = sessionData.user.relatives_attributes[n].last_name_kana
                } else {
                  delete sessionData.user.relatives_attributes[n];
                }
              }
            }
            // reset key 1 to 0
            for (let s in sessionData.user.relatives_attributes) {
              if (s == 1) {
                sessionData.user.relatives_attributes['0'] = {...sessionData.user.relatives_attributes[s]};
                delete sessionData.user.relatives_attributes[s];
              }
            }
            localStorage.setItem('userForm', JSON.stringify(sessionData));
          }
        } else if (!isUseUserData) {
          this.relativesData = {...storedData.relatives_attributes};
        }
        this.form = {
          lastName: storedData.last_name,
          firstName: storedData.first_name,
          lastNameKana: storedData.last_name_kana,
          firstNameKana: storedData.first_name_kana,
          gender: storedData.gender,
          phone: setMask(storedData.phone, [3, 4, 4], '-'),
          email: storedData.register_email || storedData.email,
          dateOfBirth: storedData.date_of_birth,
          postalCode: storedData.postal_code,
          prefecture: storedData.prefecture_name,
          municipality: storedData.municipality,
          town: storedData.town,
          detail_address: storedData.detail_address,
          address: storedData.address,
        }
        for (let i in this.relativesData) {
          if (this.relativesData[i].gender == 'male') {
            this.form.lastNameFather = this.relativesData[i].last_name;
            this.form.firstNameFather = this.relativesData[i].first_name;
            this.form.lastNameFatherKana = this.relativesData[i].last_name_kana;
            this.form.firstNameFatherKana = this.relativesData[i].first_name_kana;
          } else {
            this.form.lastNameMother = this.relativesData[i].last_name;
            this.form.firstNameMother = this.relativesData[i].first_name;
            this.form.lastNameMotherKana = this.relativesData[i].last_name_kana;
            this.form.firstNameMotherKana = this.relativesData[i].first_name_kana;
          }
        }
        if (!!this.form.prefecture && !!this.form.municipality && !!this.form.town) {
          this.prefectureCityTownVal = `${this.form.prefecture}${this.form.municipality}${this.form.town}`;
        }
      }
      localStorage.removeItem('quotationChange');
    },
    generateFormData (form) {
      let user = {
        last_name: form.lastName,
        first_name: form.firstName,
        last_name_kana: form.lastNameKana,
        first_name_kana: form.firstNameKana,
        date_of_birth: form.dateOfBirth,
        gender: form.gender,
        phone: form.phone.replaceAll('-', ''),
        register_email: form.email,
        postal_code: form.postalCode,
        prefecture_name: form.prefecture,
        municipality: form.municipality,
        town: form.town,
        detail_address: form.detail_address,
        address: form.address,
        relatives_attributes: {},
      }
      for (let r in this.relativesData) {
        user.relatives_attributes[r] = {...this.relativesData[r]};
        if (this.relativesData[r].gender == 'male') {
          user.relatives_attributes[r].last_name = form.lastNameFather;
          user.relatives_attributes[r].first_name = form.firstNameFather;
          user.relatives_attributes[r].last_name_kana = form.lastNameFatherKana;
          user.relatives_attributes[r].first_name_kana = form.firstNameFatherKana;
        } else {
          user.relatives_attributes[r].last_name = form.lastNameMother;
          user.relatives_attributes[r].first_name = form.firstNameMother;
          user.relatives_attributes[r].last_name_kana = form.lastNameMotherKana;
          user.relatives_attributes[r].first_name_kana = form.firstNameMotherKana;
        }
      }
      return {
        user: {...user}
      }
    },
    fillDob (date, isDirty) {
      this.form.dateOfBirth = date;
      this.handleCheckServerError('user_date_of_birth', ['date_of_birth'])
      if (isDirty) {
        this.v$.form.dateOfBirth.$touch();
      }
    },
    fillLocation (prefecture, municipality, town, postalCode) {
      this.form.prefecture = prefecture;
      this.form.municipality = municipality;
      this.form.town = town;
      this.form.postalCode = postalCode;
      this.v$.form.postalCode.$touch();
      this.v$.form.prefecture.$touch();
      this.v$.form.municipality.$touch();
      this.v$.form.town.$touch();
      this.prefectureCityTownVal = !prefecture && !municipality && !town ? '' : `${prefecture}${municipality}${town}`;
      this.handleCheckLength('total-address');
    },
    checkPostalCodeError (errorState) {
      this.postalCodeErr = errorState;
    },
    setQuotationOpen (state) {
      this.isQuotationOpen = state;
    }
  },
  computed: {
    getMaskValue () {
      return (e, field, limitLength) => {
        const fieldEle = e.target;
        const blocks = JSON.parse(fieldEle.getAttribute('data-mask-blocks'));
        const delimiter = fieldEle.getAttribute('data-mask-delimiter');
        this.form[field] = setMask(e.target.value, blocks, delimiter)
        this.form[field] = setLimitLength(this.form[field], limitLength);
        fieldEle.value = this.form[field];
      }
    },
    setKana () {
      return (e, field, targetField) => {
        const delayKanaValue = 50;
        if (!!e.target.value) {
          setTimeout(() => {
            if (!autoKanaNameField[field].getFurigana()) return;
            this.form[targetField] = autoKanaNameField[field].getFurigana();
          }, delayKanaValue);
        } else {
          this.form[targetField] = '';
        }
      }
    },
    setTouchParallel () {
      return (targetField, inputField) => {
        if (this.v$.form[inputField].$dirty && !!this.form[targetField]) {
          this.v$.form[targetField].$touch();
        }
      }
    },
    checkFieldLength () {
      return (fields, trackField, lengthNum) => {
        const groupLength = document.getElementById(fields[0]['id']).getAttribute('data-group-length');
        const groupEles = document.querySelectorAll(`[data-group-length="${groupLength}"]`);
        const delay = 100;
        let totalLength = 0;
        // set delay for get length value of field
        setTimeout(() => {
          for (let i = 0; i < groupEles.length; i++) {
            totalLength += groupEles[i].value.length;
          }
          this.fieldLengthErr[trackField] = totalLength > lengthNum ? true : false;
        }, delay);
      }
    },
    handleCheckLength() {
      return (type) => {
        switch (type) {
          case 'total-address':
            this.checkFieldLength([{ id: 'user_prefecture', model: 'prefecture'}, { id: 'user_municipality', model: 'municipality'}, { id: 'user_town', model: 'town'}, { id: 'user_detail_address', model: 'detail_address'}, { id: 'user_address', model: 'address'}], 'totalAddress', 45)
            break;

          case 'father':
            this.checkFieldLength([{ id: 'user_relative_attributes_0_last_name', model: 'lastNameFather'}, { id: 'user_relative_attributes_0_first_name', model: 'firstNameFather'}], 'fullNameFather', 14)
            this.checkFieldLength([{ id: 'user_relative_attributes_0_last_name_kana', model: 'lastNameFatherKana'}, { id: 'user_relative_attributes_0_first_name_kana', model: 'firstNameFatherKana'}], 'fullNameFatherKana', 19)
            break;

          case 'father-kana':
            this.checkFieldLength([{ id: 'user_relative_attributes_0_last_name_kana', model: 'lastNameFatherKana'}, { id: 'user_relative_attributes_0_first_name_kana', model: 'firstNameFatherKana'}], 'fullNameFatherKana', 19)
            break;

          case 'mother':
            this.checkFieldLength([{ id: 'user_relative_attributes_1_last_name', model: 'lastNameMother'}, { id: 'user_relative_attributes_1_first_name', model: 'firstNameMother'}], 'fullNameMother', 14)
            this.checkFieldLength([{ id: 'user_relative_attributes_1_last_name_kana', model: 'lastNameMotherKana'}, { id: 'user_relative_attributes_1_first_name_kana', model: 'firstNameMotherKana'}], 'fullNameMotherKana', 19)
            break;

          case 'mother-kana':
            this.checkFieldLength([{ id: 'user_relative_attributes_1_last_name_kana', model: 'lastNameMotherKana'}, { id: 'user_relative_attributes_1_first_name_kana', model: 'firstNameMotherKana'}], 'fullNameMotherKana', 19)
            break;

          default:
            this.checkFieldLength([{ id: 'user_last_name_kana', model: 'lastNameKana'}, { id: 'user_first_name_kana', model: 'firstNameKana'}], 'fullNameKana', 19)
            break;
        }
      }
    },
    handleCheckServerError () {
      return (id, fields) => {
        const curField = document.getElementById(id);
        if (curField.classList.contains('form-invalid-server')) {
          for (let f = 0; f < fields.length; f++) {
            this.fieldServerErr[fields[f]] = false;
          }
        }
      }
    },
    handleCloseTooltip () {
      return (tooltipInstance) => {
        const closeBtn = tooltipInstance.popper.querySelector('.btn-close-tooltip');
        closeBtn.addEventListener('click', () => {
          tooltipInstance.hide();
        });
      }
    },
    handleCheckFormStatus () {
      return () => {
        for (let formItem of this.formItemExist) {
          this.cantSubmitForm = !this.form[formItem];
          if (!this.form[formItem]) break;
        }
      }
    }
  },
  watch: {
    form: {
      handler: function () {
        this.handleCheckFormStatus();
      },
      deep: true
    }
  },
  validations () {
    const form = {
      firstName: { required, minLength: minLength(1), maxLength: maxLength(10), hiraganaKanjiKana },
      lastName: { required, minLength: minLength(1), maxLength: maxLength(10), hiraganaKanjiKana, },
      firstNameKana: { required, minLength: minLength(1), maxLength: maxLength(18), katakanaOnly },
      lastNameKana: { required, minLength: minLength(1), maxLength: maxLength(18), katakanaOnly },
      dateOfBirth: { required },
      gender: { required },
      phone: { required, phone },
      email: { required, email },
      detail_address: { required },
      postalCode: { required },
      prefecture: { required },
      municipality: { required },
      town: {},
      firstNameFather: {},
      lastNameFather: {},
      firstNameFatherKana: {},
      lastNameFatherKana: {},
      firstNameMother: {},
      lastNameMother: {},
      firstNameMotherKana: {},
      lastNameMotherKana: {},
    }
    for (let r in this.relativesData) {
      if (this.relativesData[r].gender == 'male') {
        form.firstNameFather = { required, minLength: minLength(1), maxLength: maxLength(14), hiraganaKanjiKana };
        form.lastNameFather = { required, minLength: minLength(1), maxLength: maxLength(14), hiraganaKanjiKana };
        form.firstNameFatherKana = { required, minLength: minLength(1), maxLength: maxLength(18), katakanaOnly };
        form.lastNameFatherKana = { required, minLength: minLength(1), maxLength: maxLength(18), katakanaOnly };
      }
      if (this.relativesData[r].gender == 'female') {
        form.firstNameMother = { required, minLength: minLength(1), maxLength: maxLength(14), hiraganaKanjiKana };
        form.lastNameMother = { required, minLength: minLength(1), maxLength: maxLength(14), hiraganaKanjiKana };
        form.firstNameMotherKana = { required, minLength: minLength(1), maxLength: maxLength(18), katakanaOnly };
        form.lastNameMotherKana = { required, minLength: minLength(1), maxLength: maxLength(18), katakanaOnly };
      }
    }

    for (let f in form) {
      if (Object.keys(form[f]).length) {
        this.formItemExist.push(f);
      }
    }
    return {
      form: {...form}
    };
  },
  validationConfig: {
    $lazy: true,
  },
}
</script>
